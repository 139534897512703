'use strict';
const config = {};
/**
 * 클라이언트용 페이지를 만듭니다.
 * ----------------------------------------------------------------------------------------------------
 * false 접속 루트 전체가 관리자 페이지로 처리됩니다.
 */
config.devApiHost = 'http://localhost:8383'
config.prodApiHost = 'https://api.ezenjr.co.kr'
config.devUrl = 'http://localhost:8080'
config.prodUrl = 'https://ezenjr.co.kr'
config.devClassumLogin = 'https://partners-dev.classum.com/api/v1/auth/ezenac'
config.prodClassumLogin = 'https://partners.classum.com/api/v1/auth/ezenac'

config.apiHost = process.env.NODE_ENV === 'production' ? config.prodApiHost : config.devApiHost

export default config;