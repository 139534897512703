<template>
  <div>
    <h1>이용약관</h1>
    <br /><br />
    <ol class="list">
      <li @click="scrollToElement('box1')"><span class="W60 d-inline-block">제 1 조</span> 목적</li>
      <li><span class="W60 d-inline-block">제 2 조</span> 용어의 정의</li>
      <li><span class="W60 d-inline-block">제 3 조</span> 회원가입 및 자격</li>
      <li><span class="W60 d-inline-block">제 4 조</span> 개인정보의 취득 및 이용</li>
      <li><span class="W60 d-inline-block">제 5 조</span> 회원 탈퇴</li>
      <li><span class="W60 d-inline-block">제 6 조</span> 회원에 대한 통지</li>
      <li><span class="W60 d-inline-block">제 7 조</span> 교육원의 의무</li>
      <li><span class="W60 d-inline-block">제 8 조</span> 약관의 게시 및 변경</li>
      <li><span class="W60 d-inline-block">제 9 조</span> 약관의 게시 및 변경</li>
      <li><span class="W60 d-inline-block">제 10 조</span> 준거법</li>
    </ol>

    <div id="box1">
      <h5 class="list-title">제 1 조 - 목적</h5>
      <p>
        본 약관은 본 “{{name}}”(이하 "{{name2}}"이라 합니다.)과 회원에 관한 제반사항을 규정함을 목적으로 합니다.
      </p>
    </div>

    <h5 class="list-title">제 2 조 - 용어의 정의</h5>
    <p class="sub-text">이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
    <ul class="list hypen">
      <li>'회원'은 {{name2}}에 개인정보를 제공하여 회원등록을 한 자로서, {{name2}}이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</li>
      <li>'아이디(ID)'라 함은 회원 간의 식별과 {{name2}}의 서비스 이용을 위해 회원이 설정하고 {{name2}}이 부여하는 문자와 숫자의 조합을 말합니다.</li>
      <li>'암호'라 함은 회원이 부여받은 아이디와 일치된 회원임을 확인하고 회원의 정보보호를 위해 회원이 설정한 문자와 숫자의 조합을 말합니다.</li>
    </ul>


    <h5 class="list-title">제 3 조 - 회원가입 및 자격</h5>
    <ol class="list number">
      <li>
        {{name2}}이 정한 양식에 따라 회원정보를 기입한 후 회원가입을 신청함으로써 회원으로 등록됩니다.
      </li>
      <li>
        다음 각 호의 1에 해당하는 경우, {{name2}}이 임의적으로 회원가입을 인정하지 않거나 회원자격을 박탈할 수 있습니다.
        <ol class="list number-circle">
          <li>다른 사람의 명의를 사용하여 신청하였을 경우</li>
          <li>총회 및 이사회의 운영과 그 업무에 관한 사항을 감사하는 일</li>
          <li>신청 시 필수 내용을 허위로 기재하여 신청하였을 경우</li>
          <li>사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청하였을 경우</li>
          <li>다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래 질서를 위협하는 경우</li>
          <li>기타 교육원이 규정한 제반사항을 위반하여 신청하였을 경우</li>
        </ol>
      </li>
      <li>
        {{name2}}이 회원자격을 박탈하는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.
      </li>
    </ol>

    <h5 class="list-title">제 4 조 - 개인정보의 취득 및 이용</h5>
    <ol class="list number">
      <li>{{name2}}은 고객으로부터 각종 개인 신상에 관한 정보를 제공받을 수 있으며 제공받은 정보는
        {{name2}}이 제공하는 서비스를 고객이 효율적으로 이용할 수 있도록 하는데 사용될 수 있습니다.</li>
      <li>
        {{name2}}은 고객으로부터 취득한 신상정보를 고객의 사전 동의 없이 제 3자에게 제공할 수 없습니다. 단, 다음 각 호의 1에 해당하는 경우에는 예외로 합니다.
        <ol class="list number-circle">
          <li>관계법에 의거 적법한 절차에 의한 정보 기관의 요구가 있을 경우</li>
          <li>세금계산서 발급 등의 이행을 위해 부득이하게 관련 정보가 필요한 경우</li>
          <li>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
        </ol>
      </li>
    </ol>

    <h5 class="list-title">제 5 조 - 회원 탈퇴</h5>
    <ol class="list number">
      <li>회원은 {{name2}}에 언제든지 탈퇴를 요청할 수 있으며 교육원은 즉시 회원탈퇴를 처리합니다.</li>
      <li>회원이 {{name2}}에서 이용 중인 서비스의 만기일이 지나지 않은 경우 {{name2}}은 탈퇴를 처리하지 않을 수 있습니다.</li>
    </ol>

    <h5 class="list-title">제 6 조 - 회원에 대한 통지</h5>
    <ol class="list number">
      <li>{{name2}}이 회원에 대한 통지를 하는 경우, 회원이 {{name2}}에 제출한 전자우편 주소로 할 수 있습니다.</li>
      <li>{{name2}}은 불특정다수 회원에 대한 통지의 경우 {{name2}} 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.</li>
    </ol>

    <h5 class="list-title">제 7 조 - 교육원의 의무</h5>
    <p class="sub-text">회원은 본회의 대하여 다음 각 호의 권리를 가진다.</p>

    <ol class="list number">
      <li>{{name2}} 은 본 약관이 정하는 바에 따라 지속적이고 안정적인 서비스를 제공하는데 최선을 다하여야 합니다.</li>

      <li>{{name2}} 은 항상 등록자의 정보를 포함한 개인신상정보에 대하여 관리적, 기술적 안전조치를 강구하여 정보보안에 최선을 다하여야 합니다.</li>

      <li>{{name2}} 은 공정하고 건전한 운영을 통하여 전자상거래 질서유지에 최선을 다하고 지속적인 연구개발을 통하여 양질의 서비스를 제공함으로써 고객만족을 극대화하여 인터넷 사업 발전에 기여합니다.</li>

      <li>{{name2}} 은 고객으로부터 제기되는 불편사항 및 문제에 대해 정당하다고 판단될 경우 우선적으로 그 문제를 즉시 처리해야 합니다.
      단, 신속한 처리가 곤란할 경우, 고객에게 그 사유와 처리일정을 즉시 통보하여야 합니다.</li>

      <li>{{name2}} 은 소비자 보호단체 및 공공기관의 소비자 보호업무의 추진에 필요한 자료 등의 요구에 적극 협력합니다.</li>
    </ol>

    <h5 class="list-title">제 8 조 - 약관의 게시 및 변경</h5>
    <ol class="list number">
      <li>아이디와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.</li>
      <li>회원은 아이디와 비밀번호를 제 3자가 알 수 있도록 해서는 안 됩니다.</li>
      <li>회원은 본 약관 및 관계법령에서 규정한 사항을 준수해야 합니다.</li>
      <li>다음 각 호의 1에 해당하는 행위를 할 경우 이용권리가 박탈됩니다.
        <ol class="list number-circle">
          <li>범죄와 결부된다고 객관적으로 판단되는 행위</li>
          <li>기타 관계법령에 위배되는 행위</li>
        </ol>
      </li>
    </ol>

    <h5 class="list-title">제 9 조 - 약관의 게시 및 변경</h5>
    <ol class="list number">
      <li>{{name2}}은 약관의 규제 등에 관한 법률, 전자거래기본법, 정보통신망이용촉진 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
      <li>{{name2}}이 약관을 개정할 경우에는 적용일자 및 개정사유를 이메일 혹은 사이트에서 공지합니다.</li>
      <li>전 항의 방법으로 변경 고지된 약관은 기존 회원에게도 유효하게 효력이 발생합니다.</li>
      <li>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 또는 상관례에 따릅니다.</li>
    </ol>


    <h5 class="list-title">제 10 조 - 준거법</h5>

    <ol class="list number">
      <li>본 약관에 명시되지 않은 사항은 국내 관계법에 준하여 해석합니다.</li>
      <li>회원과 {{name2}} 사이에 분쟁이 발생할 경우 교육원의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.</li>
    </ol>

    <p class="sub-text mt-2">
      <strong>[부칙]</strong>
      (시행일) 본 약관은 2023년 6월 28일부터 시행합니다.
    </p>
  </div>
</template>

<style lang="scss" scoped>
ol {list-style:none; margin:0 0 1rem}
h1 {text-align:center; font-size:1.2rem;}
h4 {margin:0 0 .5rem; font-size:1rem; font-weight:400; word-wrap: break-word; word-break: keep-all;}
h2 {margin:0 0 1rem; font-size:1.1rem;}
.list-title{
  font-weight:bold;
  font-size:17px;
  padding:30px 0 10px 0;
}
.sub-text{
  font-size:15px;
  line-height:1.5;
  word-wrap: break-word;
  word-break: keep-all;
  > small {
    line-height:1.35;
  }
}
.list{
  padding:0;
  list-style: none;
  font-size:14px;
  &.hypen {
    > li {
      padding-left:15px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content:"-";
        position:absolute;
        left:0;
        top:0;
      }
    }
  }
  &.number {
    list-style: none;
    counter-reset: steps;
    > li {
      counter-increment: steps;
      padding-left:18px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content: counter(steps)")";
        position:absolute;
        left:0;
        top:0;
      }
    }
  }
  &.number-circle {
    list-style: none;
    counter-reset: steps-circle;
    margin-top:10px;
    > li {
      counter-increment: steps-circle;
      padding-left:18px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content: counter(steps-circle);
        position:absolute;
        left:-5px;
        top:1px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        height: 18px;
        width: 18px;
        line-height:16px;
        text-align: center;
        font-size:12px;
      }
    }
  }
}
.list-table{
  width:100%;
  margin:10px 0;
  border:1px solid #ddd;
  border-collapse: collapse;
  th {
    background-color:#fafafa;
    border:1px solid #ddd;
    padding:5px;
    text-align: center;
    font-size:14px;
  }
  td {
    border:1px solid #ddd;
    padding:5px;
  }
}


</style>

<script>
export default {
  data () {
    return {
      name: "(주)이젠아카데미",
      siteUrl: "https://ezenjr.co.kr/",
      name2: "이젠"
    }
  },
  methods: {
    scrollToElement(target) {
      const element = document.getElementById(target);
      if (element) {
        window.scrollTop = element.offsetTop
      }
    }
  }
}
</script>