<template>
  <v-app :class="{minify:ui.mini, 'no-layout':useLayout===false}">
    <side-menu
        v-if="useLayout"
        :mini="ui.mini"
        @menuToggle="onMenuToggle"
    />

    <v-main :class="{'other': (useLayout === false)}">

      <div class="contents-wrap">
          <v-app-bar absolute color="white" elevate-on-scroll scroll-target="#scrolling-contents" class="common-fixed-bar">
            <v-toolbar-title v-html="mainTitle&&mainTitle!==''?mainTitle:'강의 홈'" />
            <v-spacer></v-spacer>
            <div class="user-box d-flex justify-space-between align-center" :class="{opened:ui.myInfoOpened}" @mouseenter="ui.myInfoOpened=true" @mouseleave="ui.myInfoOpened=false">
              <template v-if="isLogin">
                <div class="user-photo-wrap d-flex flex-nowrap align-center justify-center">
                  <div class="d-flex align-center ml-2" @click="onMyInfoToggle">
                    <strong class="name"><span class="mr-2">{{loginUser.role.toUpperCase()}}</span>{{loginUser.nickname}}</strong>
                    <v-icon>{{ui.myInfoOpened?'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
                  </div>

                  <div class="my-info-box">
                    <v-list dense class="pt-0 pb-0 info-box-detail">
                      <v-list-item>
                        <div class="user-info ms-auto me-auto">
                          <div class="img-box">
                            <v-avatar width="70" height="70">
                              <v-img :src="loginUser.photo?loginUser.photo:'http://placehold.it/200x200'" style="background-color:#f0f0f0; margin:8px; border:1px solid rgba(255,255,255,0.75)"/>
                            </v-avatar>
                            <v-btn class="btn-upload-photo" icon small @click="$refs.uploadInput.click()"><v-icon color="#fff" small>mdi-camera</v-icon></v-btn>
                            <input type="file" hidden class="upload-input" ref="uploadInput" accept="image/*" @change="onFileChange">
                          </div>
                          <p class="user-name">
                            <span class="mr-2 auth">{{loginUser.role.toUpperCase()}}</span>
                            <span class="name">{{loginUser.nickname}}</span>
                          </p>
                        </div>
                      </v-list-item>
                      <v-divider />
                      <v-list-item link>
                        <v-list-item-title small @click="ui.infoChange=true">
                        <v-icon small left>mdi-account</v-icon> 정보수정</v-list-item-title>
                      </v-list-item>
                      <v-divider />
                      <v-list-item link>
                        <v-icon left small>mdi-lock</v-icon>
                        <v-list-item-title small @click="ui.passwordChange=true">비밀번호 변경</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item link>
                        <v-icon left small>mdi-logout</v-icon>
                        <v-list-item-title small @click="signOut">로그아웃</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </div>

                </div>
              </template>
            </div>
            <chatbot-button />
         </v-app-bar>
        <v-sheet
            id="#scrolling-contents"
            class="overflow-y-auto"
            max-height="100%" style="height:100vh;position:relative;z-index:1">
          <router-view />
        </v-sheet>
      </div>
      <div class="contents-footer">
        <nav class="footer-nav">
          <ul class="footer-nav-list">
            <li><a href="javascript:;" @click="openAgreement('site')">서비스이용약관</a></li>
            <li><a class="font-weight-bold" href="javascript:;" @click="openAgreement('privacy')">개인정보처리방침</a></li>
            <li><a href="javascript:;" @click="openAgreement('marketing')">마케팅이용약관</a></li>
          </ul>
          <v-spacer/>
          <p class="copyright">Copyright &copy; (주)이젠아카데미 all rights reserved.</p>
        </nav>
      </div>
    </v-main>

    <vue-progress-bar />

    <my-password-change v-if="ui.passwordChange" @close="ui.passwordChange=false" />
    <my-info-modify v-if="ui.infoChange" @close="ui.infoChange=false" />
    <my-info-social v-if="ui.infoSocial" @close="ui.infoSocial=false"/>

    <v-dialog
        v-model="ui.agreementOpened"
        width="500">
      <v-card style="background:#fff;">
        <v-card-title class="text-h5 grey lighten-2 d-sr-only" v-html="ui.agreementTitle"></v-card-title>

        <v-card-text style="min-height:300px;background:#fff;">
          <privacy-view class="pt-10" v-if="ui.agreementOpened&&ui.agreementType==='privacy'" />
          <site-agree-view class="pt-10" v-if="ui.agreementOpened&&ui.agreementType==='site'" />
          <marketing-view class="pt-10" v-if="ui.agreementOpened&&(ui.agreementType==='marketing' || ui.agreementType==='receive')" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <portal-target tag="div" name="popup-container" />

    <portal-target tag="div" name="popup-container2" />

    <portal-target tag="div" name="popup-container3" />
  </v-app>

</template>
<style>
.v-menu__content {background:#fff;}
</style>
<style lang="scss" scoped>
.user-box {
  position:relative;
  height:100%;
  cursor:pointer;
  .user-photo-wrap {
    margin-right:10px;
    padding-right:10px;
    position:relative;
    &:after{
      content:"";
      width:1px;
      height:16px;
      background-color:var(--color-sub-text-light);
      position:absolute;
      right:0;
      top:50%;
      margin-top:-8px;
    }

  }
  .name{
    span {
      color:var(--color-primary-light);
      font-size:14px;
    }
  }
  .my-info-box {
    visibility: hidden;
    z-index:101;
    opacity:0;
    max-height:0;
    position:absolute;
    top:calc(100% - 1px);
    right:0;
    width:180px;
    transition:  all .3s ease;
    background:#fff;
    //box-shadow: rgba(76, 78, 100, 0.08) 0 2px 9px -2px, rgba(76, 78, 100, 0.05) 0px 4px 7px 1px, rgba(76, 78, 100, 0.03) 0px 2px 6px 4px;
    border-bottom-left-radius:18px;
    border-bottom-right-radius: 18px;
  }

  &:hover {
    .my-info-box {
      visibility: visible;
      opacity: 1;
      max-height: none;
    }
  }
}
</style>
<script>
import SideMenu from "@/views/SideMenu.vue";
import MarketingView from "@/views/Student/agreement/MarketingView";
import SiteAgreeView from "@/views/Student/agreement/SiteAgreeView";
import PrivacyView from "@/views/Student/agreement/PrivacyView";
import MyInfoModify from "@/views/Student/users/MyInfoModify";
import MyPasswordChange from "@/views/Student/users/MyPasswordChange";
import MyInfoSocial from "@/views/Student/users/MyInfoSocial";
import usersModel from "@/models/users.model";
import jwt from "@/plugins/jwt";
import ChatbotButton from "@/views/ChatbotButton.vue";

export default {
  name: 'App',
  components: {
    ChatbotButton,
    SideMenu, MarketingView, SiteAgreeView, PrivacyView, MyInfoModify, MyPasswordChange,  MyInfoSocial},
  data () {
    return {
      dialog: false,
      userPhoto: null,
      isChanging: false,
      ui: {
        height:0,
        mini: false,
        agreementOpened: false,
        agreementType: '',
        agreementTitle: '',
        passwordChange: false,
        infoChange: false,
        infoWithdraw: false,
        infoSocial:false,
        myInfoOpened: false
      },
      formData: {
        agreeSite: false,
        agreePrivacy: false,
        agreeMarketing: false,
        agreeReceive: false
      },
    }
  },
  created () {
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        // parse meta tags
        this.$Progress.parseMeta(meta)
      }
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish()
    })
  },
  mounted() {
    this.onWindowResize()
    document.addEventListener('resize', this.onWindowResize)

    const menuMinify = window.localStorage.getItem('menu-minify')
    this.ui.mini = menuMinify === true || menuMinify === 'true'

    let customVH = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', customVH + 'px')
    window.addEventListener('resize', () => {
      let customVH = window.innerHeight * 0.01
      document.documentElement.style.setProperty(
          '--vh',
          customVH + 'px',
      )
    })

    this.$Progress.finish()
  },
  methods: {
    onMyInfoToggle () {
      this.ui.myInfoOpened = ! this.ui.myInfoOpened
    },
    onMenuToggle(value) {
      this.ui.mini = value;
      window.localStorage.setItem('menu-minify', this.ui.mini);
    },
    onWindowResize() {
      this.ui.height = window.innerHeight
    },
    onDialogSubmit() {
      if(this.ui.agreementType === 'site') {
        this.formData.agreeSite = true
      }
      else if(this.ui.agreementType === 'privacy') {
        this.formData.agreePrivacy = true;
      }
      else if (this.ui.agreementType === 'marketing') {
        this.formData.agreeMarketing = true;
      }
      else if (this.ui.agreementType === 'receive') {
        this.formData.agreeReceive = true;
      }

      this.dialog = false
    },
    openAgreement(type) {
      this.ui.agreementOpened = true
      this.ui.agreementType = type
      if(type === 'site') {
        this.ui.agreementTitle = '서비스 이용약관'
      }
      else if (type === 'privacy') {
        this.ui.agreementTitle = '개인정보 처리방침'
      }
      else if (type === 'marketing') {
        this.ui.agreementTitle = '마케팅 목정의 수신정보 동의'
      }
      else if (type === 'receive') {
        this.ui.agreementTitle = '광고성 수신정보 동의'
      }
    },
    signOut() {
      usersModel.signOutProcess()
    },
    /**
     * 사용자 사진 변경을 클릭하고, 이미지를 선택했을때 처리
     */
    async onFileChange () {
      const files = this.$refs.uploadInput.files;

      // 선택된 파일이 없을 경우 나가기
      if(files.length ===0) return;

      // 넘겨줄 데이타를 정리하기
      const formData = new FormData();
      formData.append('userfile', this.$refs.uploadInput.files[0])

      // 일단 이미지 업로드 처리
      let photoUrl = '';
      await jwt.axios.post('/uploads/users', formData, {
        headers: {
          "Content-Type": "multipart/form-vod-data"
        }
      }).then((res) => {
        photoUrl = res.data[0]

        // 파일 선택 초기화
        this.$refs.uploadInput.value = "";
      })

      // 만약 이미지가 업로드 되지 않았다면 이후 과정은 실행하지 않음
      if(photoUrl.length === 0) {
        return;
      }

      // 업로드된 이미지의 경로로 현재 사용자의 이미지 교체하기
      await jwt.axios.patch('/v1/users/photo', {
        photo: photoUrl
      }).then(() => {
        usersModel.getMyInfo()
      })

    },
  },
  sockets: {
    disconnect () {
      this.$socket.emit('signIn', {
        nickname: this.loginUser.nickname,
        userId: this.loginUser.id * 1
      })
    }
  }
};
</script>