<template>
  <component :is="theme" />
</template>

<script>
import StudentLayout from '@/views/Student/ThemeLayout.vue'
import TeacherLayout from '@/views/Teacher/ThemeLayout.vue'
import AdminLayout from '@/views/Admin/ThemeLayout.vue'
import usersModel from '@/models/users.model'

export default {
  components: {
    'default' : StudentLayout,
    'admin' : AdminLayout,
    'teacher' : TeacherLayout
  },
  computed: {
    theme () {
      return this.$store.getters["layout/theme"]
    }
  },
  sockets: {
    duplicateLogin () {
      console.log("중복로그인 수신");
      usersModel.signOut();
      alert('중복 로그인이 감지되었습니다. 다시 로그인 해주세요.');
      this.$router.push('/sign-in'); // 로그인 페이지로 이동
    }
  }
}
</script>

<style lang="scss">
html {
  -webkit-overflow-scrolling: touch;
  overflow:hidden;
  touch-action:none;
}
html, body {
  -webkit-font-smoothing: antialiased;
  overscroll-behavior-y: contain;
  scroll-behavior: unset !important;
  scroll-snap-type: none !important;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
body {
  background-color: #f0f5f9;
}
.v-application {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}
#app {
  height: calc(var(--vh, 1vh) * 100);
  overflow-x:auto;
  overflow-y:hidden;

  .container {
    //width:1200px;
    margin:0 auto;
    min-width:1200px;
  }
  .full-container {
    min-width:1200px;
    margin:0 auto;
  }
  .v-application--wrap {
    min-height: calc(var(--vh, 1vh) * 100) !important; // 덮어씌우기
    overflow:hidden;
    box-sizing: border-box;
    min-width:1600px;
    position:relative;
    z-index:2;
  }
  &.no-layout {
    .v-application--wrap {
      min-width:0;
    }
  }
  &.admin-theme {
    .v-main__wrap {
      height:100vh;
    }
  }
  &.minify {
    .v-main {
      padding: 0 0 0 100px !important;
      &.other{
        padding: 0 0 0 0 !important;
      }
    }
  }
}
.v-main {
  flex: 1 0 auto;
  max-width: 100%;
  padding: 0 0 0 300px !important;
  background-color:var(--color-background-color);
  .v-main__wrap {
    overflow-y:auto;
    box-sizing: border-box;
    width:100%;
    height:calc(100vh - 20px);
    padding:20px 20px 0 10px;
    display:flex;
    flex-direction: column;
    z-index: 99;
    background-color:var(--color-background-color);
    .contents-wrap {
      width:100%;
      padding-top:64px;
      height:100%;
      overflow-y:hidden;
      position:relative;
    }
    .contents-footer {
      width:100%;
      padding-top:10px;

      .footer-nav {
        display:flex;

        .copyright {
          font-size:12px;
          margin:0;
        }

        .footer-nav-list {
          list-style:none;
          padding:0;
          margin:0;
          display:flex;

          li {
            display:flex;
            align-items: center;

            & + li {
              &:before {
                content:'';
                display:block;
                width:1px;
                height:8px;
                background:#898989;
                margin:0 10px;
              }
            }

            a {
              color:#787878;
              font-size:14px;
            }
          }
        }
      }
    }
  }
  &.other{
    width:100% !important;
    padding:0 0 0 0 !important;
    //overflow: hidden;
    .v-main__wrap {
      padding:0;
      height:100vh;
      .container {
        width: 100% !important;
        max-width: 100% !important;
        margin:auto !important;
        box-sizing: border-box;
      }
      .row {
        height:100vh;
        align-items: center;
        justify-content: center;
      }
      .contents-wrap {
        height:calc(var(--vh, 1vh) * 100);
        padding-top:0;
      }
      .contents-footer {
        display: none;
        height:0;
      }
      .common-fixed-bar {
        display: none;
      }
    }
  }
}
.swal2-container {
  z-index:20060 !important;
}
.full-container,
.container.full-container {
  width:100%;
  margin: 0 auto;
  .left-box {
    flex-basis: auto;
    position:relative;
    flex-shrink: 1;
    flex-grow: 1;
    min-width:0;
  }
  .right-box {
    flex-shrink: 0;
    width:370px;
    margin-left:30px;
    .inner-box {
      width:100%;
      display:flex;
      justify-content: space-between;
      flex-direction: column;
      .--home-box {
        width:100%;
      }
    }
  }
}


.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile) {
  z-index:10;
}



</style>
