<template>
    <div>
      <v-navigation-drawer app clipped v-model="drawer"
                           :mini-variant.sync="miniVariant"
                           permanent class="nav d-flex flex-column" v-scroll.self="onScroll">
        <div class="d-flex flex-column" style="height:100%;">
          <div class="logo-box align-start">
            <div class="d-flex flex-column justify-start">
              <v-img :width="120" aspect-ratio="1/1"
                     :src="require('@/assets/images/common/logo-white.svg')"
                     class="d-inline-block" v-if="!mini"></v-img>
              <v-chip v-if="!mini" fill small dark color="var(--color-primary-light)" class="mt-2">TEACHER MANAGEMENT</v-chip>
            </div>

            <v-btn v-if="!mini" icon @click.stop="menuToggle" class="btn-close ml-auto" title="메뉴 닫기">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn v-if="mini" icon @click.stop="menuToggle" class="btn-open" title="메뉴 열기">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

          <v-list style="flex:1" class="menu-list">
            <v-list-item link to="/" title="나의 강의">
              <v-list-item-icon><v-icon>mdi-developer-board</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>나의 강의</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/schedule" title="전체 일정 관리">
              <v-list-item-icon><v-icon>mdi-clipboard-text</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>전체 일정 관리</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/coding-editor" title="코드씽크">
              <v-list-item-icon><v-icon>mdi-file-code-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>코드씽크</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/coding-test" title="코드위드미">
              <v-list-item-icon><v-icon> mdi-comment-text-multiple</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>코드위드미</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/code-review" title="코드 리뷰">
              <v-list-item-icon><v-icon>mdi-xml</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>코드 리뷰</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- v-list-item link title="PBT 역량평가 리포트" to="/pbt-report">
              <v-list-item-icon><v-icon>mdi-chart-pie-outline</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>PBT 역량평가 리포트</v-list-item-title>
              </v-list-item-content>
            </v-list-item -->

          </v-list>
          <div class="pt-2" />
        </div>
      </v-navigation-drawer>
    </div>
</template>

<style>
.v-navigation-drawer__content { flex: 0 1 auto; height: 100%; max-width: 100%; overflow: hidden; }
.nav {  background-color:var(--color-primary-dark) !important; left: 20px; transform: translateX(0%); position: fixed; height: calc(100% - 40px) !important; top: 20px !important; bottom: 20px; width: 270px; border-radius: 18px;}
</style>

<style lang="scss" scoped>
.--main-title {white-space:nowrap;}

.logo-title { color:#1e1e1e; font-size:15px; margin-left:15px; line-height:1.2; font-weight: 600;}
.logo-title > strong { font-size:25px; font-weight: 800; letter-spacing:6px; color:#1e1e1e; }
.btn-close {color:#fff !important; background-color:#1e1e1e; border:1px solid #fff !important; transform: scale(0.75); }
.btn-open {color:#fff !important; background-color:#1e1e1e; border:1px solid #fff !important; transform: scale(0.75); margin-left:-10px; }

.menu-list { box-sizing: border-box; display: flex; flex-direction: column; overflow-y: auto; }

.v-list-item__icon {
  margin-top:0;
  margin-bottom:0;
  align-self: center;

  .v-icon {
    font-size:1.5rem;
    display:block;
    margin:0 auto;
    color:rgba(76, 78, 100, 0.87);
  }
}
.nav .v-list-item {
  position:relative;
  color:#383838;
  min-height:0;
  font-weight:500;
  font-size:1rem;
  flex:none;
  block-size: 2.625rem;
  display:flex;
  align-items: center;
  &:before{
    left:-16px;
    border-radius: 0 50px 50px 0;
    transform: translate(-100%, 0);
  }

  & + .v-list-item {
    margin-top:.25rem;
  }

  &:hover {
    &:before {
      background-color:#f0f0f0;
      opacity:1;
    }
  }

  .v-list-item__content {
    padding:0;
  }

  &:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--color-sub-text-light);
    .v-icon {
      color: var(--color-sub-text-light);
    }
    &:hover {
      color:var(--color-primary-light);

      .v-icon {
        color:var(--color-primary-light);
      }
    }
  }

  &.v-list-item--active {
    opacity:1;
    color:#fff;
    box-shadow: 0 4px 8px -4px rgba(var(--color-primary),.42),0 0 0 transparent,0 0 transparent;

    .v-icon {
      color:#fff;
    }

    &:before{
      background-color:var(--color-primary);
      opacity: 0.5;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      left:-16px;
      border-radius: 0 50px 50px 0;
      transform: translate(0, 0);
    }
  }
}
.v-list-item--link:before { border-radius: .25rem; margin:0 5px; background-color: #fff;}
.v-list-item--link.v-list-item--active:before {opacity: 1}
.v-list-item__icon {margin-right:5px; position:relative; z-index:2;}
.v-list-item__title { position:relative; z-index: 2;}
.v-application--is-ltr .v-list-item__icon:first-child{margin-right:20px;}
.logo-box {padding:30px 10px 30px 20px; text-align: center; display:flex; align-items: center;}
.img-box-container {
  width:80px;
  height:60px;
  text-align: center;
}
.user-info-container,
.user-photo-wrap  {width: 100%;}
.minify {
  .nav .v-list-item.other-btn {
    padding:0 6px;
  }
  .user-photo-wrap {
    transform: scale(0.65);
    margin-left:30px;
  }
  .menu-list .v-icon { padding-left:0;}
}
</style>

<script>
import usersModel from "@/models/users.model";
import jwt from "@/plugins/jwt";

export default {
  props: {
    mini: {
      type: Boolean,
      default : false,
      required: false
    }
  },
  mounted () {
    this.miniVariant = this.mini
  },
  data () {
    return {
      dialog: false,
      drawer: true,
      scrollInvoked:0,
      userPhoto: null,
      isChanging: false,
      ui: {
        passwordChange: false,
        infoChange: false,
        infoWithdraw: false,
        infoSocial:false,
        chatGPT: false
      },
      miniVariant: false,
      myLectureList: {
        result: [],
        totalCount: 0,
        page: 1,
        pageRows: 5
      }
    }
  },
  watch: {
    miniVariant () {
      if(this.isChanging) return;
      this.isChanging = true;
      this.$emit('menuToggle', this.miniVariant)
      this.isChanging  =false
    },
    mini () {
      this.miniVariant = this.mini
    }
  },
  methods: {
    menuToggle () {
      this.miniVariant= !this.miniVariant
    },
    signOut() {
      usersModel.signOutProcess()
    },
    /**
     * 사용자 사진 변경을 클릭하고, 이미지를 선택했을때 처리
     */
    async onFileChange () {
      const files = this.$refs.uploadInput.files;

      // 선택된 파일이 없을 경우 나가기
      if(files.length ===0) return;

      // 넘겨줄 데이타를 정리하기
      const formData = new FormData();
      formData.append('userfile', this.$refs.uploadInput.files[0])

      // 일단 이미지 업로드 처리
      let photoUrl = '';
      await jwt.axios.post('/uploads/users', formData, {
        headers: {
          "Content-Type": "multipart/form-vod-data"
        }
      }).then((res) => {
        photoUrl = res.data[0]

        // 파일 선택 초기화
        this.$refs.uploadInput.value = "";
      })

      // 만약 이미지가 업로드 되지 않았다면 이후 과정은 실행하지 않음
      if(photoUrl.length === 0) {
        return;
      }

      // 업로드된 이미지의 경로로 현재 사용자의 이미지 교체하기
      await jwt.axios.patch('/v1/users/photo', {
        photo: photoUrl
      }).then(() => {
        usersModel.getMyInfo()
      })

    },
    onScroll () {
      this.scrollInvoked++
    },
  },
  computed: {
    usersModel() {
      return usersModel
    }
  }


}
</script>
