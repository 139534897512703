<template>
  <v-navigation-drawer v-model="drawer"
                             :mini-variant.sync="miniVariant"
                              class="nav d-flex flex-column" v-scroll.self="onScroll">
          <div class="d-flex flex-column" style="height:100%;">
            <div class="logo-box">
              <v-img :width="120" aspect-ratio="1/1"
                     :src="require('@/assets/images/common/logo-white.svg')"
                     class="d-inline-block" v-if="!mini"></v-img>
              <v-btn v-if="!mini" icon @click.stop="menuToggle" class="btn-close" title="메뉴 닫기">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn v-if="mini" icon @click.stop="menuToggle" class="btn-open" title="메뉴 열기">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <v-list style="flex:1" class="menu-list">

              <v-list-item link to="/" title="홈">
                <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>홈</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/coding-editor" title="코드씽크" v-if="loginUser.isIT">
                <v-list-item-icon><v-icon>mdi-file-code-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>코드씽크</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/coding-test" title="코드위드미" v-if="loginUser.isIT">
                <v-list-item-icon><v-icon> mdi-comment-text-multiple</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>코드위드미</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/code-review" title="코드 리뷰" v-if="loginUser.isIT">
                <v-list-item-icon><v-icon>mdi-xml</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>코드 리뷰</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link to="/quiz" title="코딩 퀴즈" v-if="loginUser.isIT">
                <v-list-item-icon><v-icon>mdi-quora</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>퀴즈코딩</v-list-item-title>
                </v-list-item-content>
              </v-list-item>


              <!-- v-list-item link to="/pbt-report" title="PBT 역량평가 리포트">
                <v-list-item-icon><v-icon>mdi-chart-pie-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>PBT 역량평가 리포트</v-list-item-title>
                </v-list-item-content>
              </v-list-item -->


            </v-list>

            <v-divider class="mt-1 pb-1" style="border-color:rgba(255,255,255,0.15)" />

            <v-list>

              <v-list-item link title="훈련생 유의사항" href="https://ezen-academy.gitbook.io/lms_precautions/" target="_blank" >
                <v-list-item-icon><v-icon>mdi-alert-circle-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>훈련생 유의사항</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link title="기초 IT 용어집" href="https://ezen-academy.gitbook.io/ezen-it-book-web-development/" target="_blank">
                <v-list-item-icon><v-icon>mdi-book-open-page-variant-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>기초 IT 용어집</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- v-list-item link title="Smart LMS 사용자 가이드" href="https://docs.codelabit.co.kr/codelab-smart-lms-1/" target="_blank">
                <v-list-item-icon><v-icon>mdi-book-search-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="letter-spacing: -0.05em;">LMS 사용자 가이드</v-list-item-title>
                </v-list-item-content>
              </v-list-item -->

              <!-- v-list-item dense link :ripple="false" title="기업프로젝트 협력신청" href="https://docs.google.com/forms/d/1jc0bq7uOI-mpTcflje2MdHbgEipDPzzQaZIrFaL-gX4/viewform?edit_requested=true" target="_blank" class="other-btn">
                <v-list-item-icon><v-icon>mdi-briefcase-arrow-left-right-outline</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>기업프로젝트 협력신청</v-list-item-title>
                </v-list-item-content>
              </v-list-item -->
            </v-list>

            <div class="pt-2" />
          </div>

        </v-navigation-drawer>
</template>

<style>
.v-navigation-drawer__content { flex: 0 1 auto; height: 100%; max-width: 100%; overflow: hidden; }
.nav {  background-color:var(--color-primary-dark) !important; left: 20px; transform: translateX(0%); position: fixed; height: calc(100% - 40px) !important; top: 20px !important;; bottom: 20px; width: 270px; border-radius: 18px;}
</style>

<style lang="scss" scoped>
  .--main-title {white-space:nowrap;}

  .logo-title { color:#1e1e1e; font-size:15px; margin-left:15px; line-height:1.2; font-weight: 600;}
  .logo-title > strong { font-size:25px; font-weight: 800; letter-spacing:6px; color:#1e1e1e; }
  .btn-close {color:#fff !important; background-color:#1e1e1e; border:1px solid #fff !important; transform: scale(0.75); margin-left:45px; }
  .btn-open {color:#fff !important; background-color:#1e1e1e; border:1px solid #fff !important; transform: scale(0.75); margin-left:-10px; }

  .menu-list { box-sizing: border-box; display: flex; flex-direction: column; overflow-y: auto; }
  .v-list-item__icon {
    margin-top:0;
    margin-bottom:0;
    align-self: center;

    .v-icon {
      font-size:1.5rem;
      display:block;
      margin:0 auto;
      color:rgba(76, 78, 100, 0.87);
    }
  }
  .v-list-item {
    position:relative;
    color:#383838;
    min-height:0;
    font-weight:500;
    font-size:1rem;
    flex:none;
    block-size: 2.625rem;
    display:flex;
    align-items: center;
    &:before{
      left:-16px;
      border-radius: 0 50px 50px 0;
      transform: translate(-100%, 0);
    }

    & + .v-list-item {
      margin-top:.25rem;
    }

    &:hover {
      &:before {
        background-color:#f0f0f0;
        opacity:1;
      }
    }

    .v-list-item__content {
      padding:0;
    }

    &:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: var(--color-sub-text-light);
      .v-icon {
        color: var(--color-sub-text-light);
      }
      &:hover {
        color:var(--color-primary-light);

        .v-icon {
          color:var(--color-primary-light);
        }
      }
    }

    &.v-list-item--active {
      opacity:1;
      color:#fff;
      box-shadow: 0 4px 8px -4px rgba(var(--color-primary),.42),0 0 0 transparent,0 0 transparent;

      .v-icon {
        color:#fff;
      }

      &:before{
        background-color:var(--color-primary);
        opacity: 0.5;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        left:-16px;
        border-radius: 0 50px 50px 0;
        transform: translate(0, 0);
      }
    }
  }
  .v-list-item--link:before { border-radius: .25rem; margin:0 5px; background-color: #fff;}
  .v-list-item--link.v-list-item--active:before {opacity: 1}
  .v-list-item__icon {margin-right:5px; position:relative; z-index:2;}
  .v-list-item__title { position:relative; z-index: 2;}
  .v-application--is-ltr .v-list-item__icon:first-child{margin-right:20px;}
  .logo-box {padding:30px 10px 30px 20px; text-align: center; display:flex; align-items: center;}
  .img-box-container {
    width:80px;
    height:60px;
    text-align: center;
  }
  .user-info-container,
  .user-photo-wrap  {width: 100%;}
  .minify {
    .nav .v-list-item.other-btn {
      padding:0 6px;
    }
    .user-photo-wrap {
      transform: scale(0.65);
      margin-left:30px;
    }
    .menu-list .v-icon { padding-left:0;}
  }
</style>

<script>
import usersModel from "@/models/users.model";
import jwt from "@/plugins/jwt";

export default {
  props: {
    mini: {
      type: Boolean,
      default : false,
      required: false
    }
  },
  mounted () {
    this.miniVariant = this.mini
  },
  data () {
    return {
      dialog: false,
      drawer: true,
      scrollInvoked:0,
      userPhoto: null,
      isChanging: false,
      ui: {
        chatGPT: false
      },
      miniVariant: false,
      myLectureList: {
        result: [],
        totalCount: 0,
        page: 1,
        pageRows: 5
      }
    }
  },
  watch: {
    miniVariant () {
      if(this.isChanging) return;
      this.isChanging = true;
      this.$emit('menuToggle', this.miniVariant)
      this.isChanging  =false
    },
    mini () {
      this.miniVariant = this.mini
    }
  },
  methods: {
    menuToggle () {
      this.miniVariant= !this.miniVariant
    },
    onScroll () {
      this.scrollInvoked++
    },
  },


}
</script>
