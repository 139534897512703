<template>
  <div>
    <h1>마케팅 정보 수신 동의서</h1>
    <br>
    <h4>{{name}}(이하 "{{name2}}")은 개인정보보호법 및 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 따라 광고성 정보를 전송하기 위해 이용자의 사전 수신 동의를 받고 있습니다.</h4>
    <h4 class="mt-2">본 내용은 사이트 {{name2}}({{siteUrl}}) 회원 서비스가 적용되는 개별서비스의 마케팅 수신동의를 포함하고 있으며, 마케팅 정보 수신 동의 시개별서비스의 마케팅 수신에 일괄 동의한 것으로 처리됩니다.</h4>
    <br /><br />
    <ol class="list number">
      <li>전송방법
        <ul class="list hypen">
          <li>마케팅 정보 전송 방법은 각 개별서비스에서 정한 방식에 따라 전송됩니다.</li>
        </ul>
      </li>
      <li>전송내용
        <ul class="list hypen">
          <li>발송되는 마케팅 정보는 수신자에게 개별서비스에서 제공하는 혜택(포인트, 쿠폰 등) 정보, 각종 이벤트, 신규 상품 관련 소식 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. <br>
            단, 광고성 정보 이외에 의무적으로 안내 되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다.</li>
        </ul>
      </li>
      <li>수집항목
        <ul class="list hypen">
          <li>이름, 휴대폰번호, 이메일 주소</li>
        </ul>
      </li>
      <li>이용목적
        <ul class="list hypen">
          <li>소식지 서비스 제공, 신제품 및 이벤트 안내, 이벤트 경품/사은품 제공, 할인행사, 고객 맞춤 마케팅/판촉 등 관련 이메일 및 SMS 등 발송</li>
        </ul>
      </li>
      <li>철회안내
        <ul class="list hypen">
          <li>수신동의 이후에라도 언제든지 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용하실 수 있습니다. <br>
            다만 수신 거부할 경우 신규 서비스나 상품 관련 소식 등의 마케팅 정보를 제공받지 못할 수 있습니다.</li>
        </ul>
      </li>
      <li>수신동의 변경 및 보유기간
        <ul class="list hypen">
          <li>보유 기간 : 마케팅 정보 수신 동의로부터 2년, 기간 초과 시 동의 절차 재진행 (미동의 시, 즉시 파기)</li>
          <li>{{name2}} 정보수정 페이지에서 개별서비스의 마케팅 수신동의를 변경(동의/철회)할 수 있으며, 동의일로부터 회원 탈퇴 혹은 마케팅 수신 동의 해제 시까지 광고성 정보 전달을 위하여 보유ㆍ이용 됩니다.</li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<style lang="scss" scoped>
ol {list-style:none; margin:0 0 1rem}
h1 {text-align:center; font-size:1.2rem;}
h4 {margin:0 0 .5rem; font-size:1rem; font-weight:400; word-wrap: break-word; word-break: keep-all;}
h2 {margin:0 0 1rem; font-size:1.1rem;}
.list-title{
  font-weight:bold;
  font-size:17px;
  padding:30px 0 10px 0;
}
.sub-text{
  font-size:15px;
  line-height:1.5;
  word-wrap: break-word;
  word-break: keep-all;
  > small {
    line-height:1.35;
  }
}
.list{
  padding:0;
  list-style: none;
  font-size:14px;
  &.hypen {
    > li {
      padding-left:15px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content:"-";
        position:absolute;
        left:0;
        top:0;
      }
    }
  }
  &.number {
    list-style: none;
    counter-reset: steps;
    > li {
      counter-increment: steps;
      padding-left:18px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content: counter(steps)")";
        position:absolute;
        left:0;
        top:0;
      }
    }
  }
  &.number-circle {
    list-style: none;
    counter-reset: steps-circle;
    margin-top:10px;
    > li {
      counter-increment: steps-circle;
      padding-left:18px;
      position:relative;
      word-wrap: break-word;
      word-break: keep-all;
      &:before{
        content: counter(steps-circle);
        position:absolute;
        left:-5px;
        top:1px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        height: 18px;
        width: 18px;
        line-height:16px;
        text-align: center;
        font-size:12px;
      }
    }
  }
}
.list-table{
  width:100%;
  margin:10px 0;
  border:1px solid #ddd;
  border-collapse: collapse;
  th {
    background-color:#fafafa;
    border:1px solid #ddd;
    padding:5px;
    text-align: center;
    font-size:14px;
  }
  td {
    border:1px solid #ddd;
    padding:5px;
  }
}


</style>
<script>
export default {
  data () {
    return {
      name: "(주)이젠아카데미",
      siteUrl: "https://ezenjr.co.kr/",
      name2: "이젠"
    }
  },
}
</script>