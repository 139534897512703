export default [
    {path: '/', name:'AdminDashBoard', component: () => import(/* webpackChunkName: "admin.dashbaord" */ '@/views/Admin/DashBoard.vue')},
    {path: '/sign-in', name:'SignIn',component: () => import(/* webpackChunkName: "admin.users.signIn" */ '@/views/Admin/AdminSignIn.vue'), meta: {requiredLogin:false, useLayout:false} },
    {path: '/lectures', name:'LectureList',component: () => import(/* webpackChunkName: "admin.lectures.list" */ '@/views/Admin/lectures/LectureList.vue')},
    {path: '/users', name:'UserList',component: () => import(/* webpackChunkName: "admin.users" */ '@/views/Admin/UserList.vue')},
    {path: '/access', name:'UserAccess',component: () => import(/* webpackChunkName: "admin.users" */ '@/views/Admin/UserAccess.vue')},
    {path: '/classroom', name:'ClassRoomList',component: () => import(/* webpackChunkName: "admin.classroom" */ '@/views/Admin/ClassRoomList.vue')},
    {path: '/schedule', name:'ScheduleManger',component: () => import(/* webpackChunkName: "admin.schedule" */ '@/views/Admin/ScheduleManager.vue')},
    {path: '/ai-model', name:'AiModelManager',component: () => import(/* webpackChunkName: "admin.ai.manager" */ '@/views/Admin/AiModelManager.vue')},
    {path: '/test', name:'Test',component: () => import(/* webpackChunkName: "admin.ai.test" */ '@/views/Admin/Test.vue')},
    {path: '/pbt-report', name:'PBTReport', component: () => import(/* webpackChunkName: "admin.pbt_report" */ '@/views/Student/lectures/EvaluationReport.vue')}
]