<template>
  <portal :to="to" :disabled="portalDisabled">
    <dialog class="--popup" @click.stop="onBackClick">
      <div class="--inner" :class="{full: fullBody}" :style="popupBoxStyle">
        <div class="--header">
          <h4 class="--title">{{title}}</h4>
          <div class="--header-action">
            <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </div>
        <div class="--body" :class="{'no-overflow': noOverflow}"><slot /></div>
      </div>
    </dialog>
  </portal>
</template>

<style lang="scss" scoped>
.--popup {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:10000;
  display:block;
  margin:0;
  border:0;
  padding:0;
  background:transparent;

  .--inner {
    max-height:100%;
    display:flex;
    flex-direction: column;
    background:var(--color-background-color);
    max-width:100%;
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:10001;
    padding:1rem;
    border: 1px solid #e5eaef;
    border-radius: 18px;

    &.full {
      padding:0;
    }

  }

  .--header {
    display:flex;
    align-items: center;
    flex-shrink: 0;
    margin-bottom:10px;
    position:relative;
    .--title {
      flex:1;
      margin-right:1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow:hidden;
      font-size:22px;
      font-weight:800;
    }

    .--header-action {
      position: absolute;
      background-color: #fff;
      top: -30px;
      right:-35px;
      border-radius:30px;
      box-shadow: rgba(76, 78, 100, 0.08) 0 2px 9px -2px, rgba(76, 78, 100, 0.05) 0px 4px 7px 1px, rgba(76, 78, 100, 0.03) 0px 2px 6px 4px;
    }
  }

  .--body {
    flex:1;
    overflow-y:auto;
    overflow-x:hidden;
    &.other {
      display:flex;
      overflow-y:hidden;
    }
    &.no-overflow {
      overflow-y:hidden;
    }
  }

  &:after {
    content:'';
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10000;
    background-color:rgba(0,0,0,0.75);
  }

  &.other{
    .--body {
      overflow-y: hidden;
      display: flex;
    }
  }
}
</style>

<script>

export default {
  props: {
    fullBody: {
      type:Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: 'popup-container'
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    backdrop: {
      type: Boolean,
      required: false,
      default: false
    },
    maxWidth: {
      type: String,
      required: false,
      default: '90%'
    },
    maxHeight: {
      type: String,
      required: false,
      default: '90%'
    },
    width: {
      type: [Number, String],
      required: false,
      default: 400
    },
    height: {
      type: [Number, String],
      required: false,
      default: null
    },
    backgroundColor: {
      type: String,
      required: false,
      default : '#fff'
    },
    portalDisabled: {
      type:Boolean,
      required: false,
      default: false
    },
    noOverflow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    popupBoxStyle () {
      let width = typeof this.width === 'number' ? `${this.width}px` : this.width;

      if(width.indexOf('%') <0 && width.indexOf('px') <0 && width.indexOf('rem') <0 && width.indexOf('em') < 0) {
        width += "px"
      }

      const style = {
        width: width,
        maxHeight: this.maxHeight,
        maxWidth: this.maxWidth,
        backgroundColor: this.backgroundColor
      }

      if(this.height !== null) {
        let height = typeof this.height === 'number' ? `${this.height}px` : this.height;

        if(height.indexOf('%') <0 && height.indexOf('px') <0 && height.indexOf('rem') <0 && height.indexOf('em') < 0) {
          height += "px"
        }

        style.height = height
      }


      return style
    }
  },
  mounted () {
    //document.querySelector('.v-main__wrap').style.overflowY = 'hidden'
  },
  destroyed() {
    //document.querySelector('.v-main__wrap').style.overflowY = 'auto'
  },
  methods: {
    onBackClick () {
      if(this.backdrop) {
        this.$emit('close')
      }
    },
    onClose() {
      this.$emit('close')
    },
  }
}
</script>